<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      'Team': 'Time',
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Points': 'Pontos',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Pontos',
    },
    es: {
      'Team': 'Equipo',
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Points': 'Puntos',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
      'Total Bonus': 'Total de Bônus',
      'Total Points': 'Total de Puntos',
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      leads: {
        status: null,
        modal: false,
        loading: false,
        required: "yes",
        type: "manual",
      },

      show: true,

      total: {
        sponsored: "-",
        bonus: "R$ -,--",
        balance: "R$ -,--",
      },

      monthly: 'inactive',

      statData: null,

      binary: {
        modal: false,
        loading: false,
        side: "",

        points: {
          left: 0,
          right: 0,
        },
        percent: 0,
        bonus: "R$ -,--",
      },

      referral: {
        sponsored: process.env.VUE_APP_URL_PAINEL + this.$store.state.account.user.username,
        go: process.env.VUE_APP_URL_GO + this.$store.state.account.user.username,
      }
    };
  },
  methods: {
    getLeads() {
      api
        .get("leads/day")
        .then((response) => {
          if (response.data.status == "success") {
            this.leads.status = response.data.leads.status
          }
        });
    },
    getLeadsConfig() {
      api
        .get('leads/config')
        .then(response => {
          if (response.data.status == 'success') {
            this.leads.required = response.data.required;
            this.leads.type = response.data.type;
          }
        })
    },
    showLeadsConfig() {
      if (this.leads.required == 'yes') {
        this.leads.modal = false;
        this.leads.modal = true;
      }
    },
    saveLeadsConfig() {
      this.leads.loading = true;

      api
        .post('leads/config', {
          type: this.leads.type
        })
        .then(response => {
          if (response.data.status == 'success') {
            this.leads.modal = false;
            this.leads.loading = false;

            this.$toast.success('A configuração foi salva com sucesso.')
            this.getLeads();
          }
        })
        .finally(() => {
          this.binary.loading = false;
        })
    },
    getMonthly() {
      api
        .get('reports/monthly')
        .then(response => {
          this.monthly = response.data.status
          this.getStatData();
        })
    },
    getTotalSponsored() {
      api
        .get('network/sponsored/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.sponsored = response.data.total
            this.getStatData();
          }
        })
    },
    getTotalBonus() {
      api
        .get('reports/bonus/total')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.bonus = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance')
        .then(response => {
          if (response.data.status == 'success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          title: "Plano",
          value: this.account.user.package
        },
        {
          title: "Total de Indicados",
          value: (this.show) ? this.total.sponsored.toString() : "**"
        },
        {
          title: "Total de Bonificações",
          value: (this.show) ? this.total.bonus : "R$ ***,**"
        },
        {
          title: "Saldo Disponível",
          value: (this.show) ? this.total.balance : "R$ ***,**"
        }
      ]
    },
    getBinaryResume() {
      api
        .get('network/binary')
        .then(response => {
          if (response.data.status == 'success') {
            this.binary.points.left = this.$options.filters.points(response.data.resume.points.left);
            this.binary.points.right = this.$options.filters.points(response.data.resume.points.right);
            this.binary.percent = this.$options.filters.points(response.data.resume.percent);
            this.binary.bonus = this.$options.filters.currency(response.data.resume.bonus);
          }
        })
    },
    getBinarySide() {
      api
        .get('network/binary/side')
        .then(response => {
          if (response.data.status == 'success') {
            this.binary.side = response.data.side
          }
        })
    },
    changeBinarySide() {
      this.binary.modal = false;
      this.binary.modal = true;
      this.binary.loading = false;
    },
    saveBinarySide() {
      this.binary.loading = true;

      api
        .post('network/binary/side', {
          side: this.binary.side
        })
        .then(response => {
          if (response.data.status == 'success') {
            this.binary.modal = false;
            this.binary.loading = false;
            this.$toast.success('O lado de derramamento foi alterado com sucesso!')
            this.getBinarySide();
          }
        })
        .finally(() => {
          this.binary.loading = false;
        })
    }
  },
  mounted() {
    this.getLeads()
    this.getLeadsConfig()
    this.getMonthly()
    this.getTotalSponsored()
    this.getTotalBonus()
    this.getTotalBalance()
    this.getStatData()
    this.getBinaryResume()
    this.getBinarySide()
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <div class="page-title-box d-flex justify-content-between align-items-center">
      <div>
        <h5>Bem-vindo à Líder Leads</h5>
        <p>Confira um resumo da sua conta.</p>
      </div>
    </div>

    <fieldset class="leads d-flex justify-content-between align-items-center">
      <div class="leads-title">
        <h5 class="m-0">Indique um amigo</h5>
      </div>
      <div class="leads-buttons d-flex flex-gap-5">
        <div>
          <ShareNetwork network="whatsapp" url="" :title="referral.sponsored" class="btn btn-outline-success p-2 rounded d-flex align-items-center justify-content-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263">
              </path>
            </svg>
          </ShareNetwork>
        </div>
        <div>
          <button class="btn btn-outline-default p-2 rounded d-flex align-items-center justify-content-center" v-clipboard:copy="referral.sponsored" v-on:click="$toast.success('O link foi copiado!')">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
              <path
                d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
              </path>
            </svg>
          </button>
        </div>
      </div>
    </fieldset>

    <fieldset v-if="leads && leads.status" class="leads d-flex justify-content-between align-items-center">
      <div class="leads-title">
        <h5 class="m-0">Atividade diária</h5>
      </div>
      <div class="leads-status">
        <div v-if="leads.status == 'approved'" class="border border-success rounded px-5 py-2 text-success">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
            <circle cx="8.5" cy="9.5" r="1.5"></circle>
            <circle cx="15.493" cy="9.493" r="1.493"></circle>
            <path d="M12 18c5 0 6-5 6-5H6s1 5 6 5z"></path>
          </svg>
          REALIZADO
        </div>
        <div v-else-if="leads.status == 'auto'" class="border border-default rounded px-5 py-2 text-default">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path
              d="M19 3h-3c-1.103 0-2 .897-2 2v8c0 1.103-.897 2-2 2s-2-.897-2-2V5c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2v8c0 4.963 4.037 9 9 9s9-4.037 9-9V5c0-1.103-.897-2-2-2zm-3 2h3v3h-3V5zM5 5h3v3H5V5zm7 15c-3.859 0-7-3.141-7-7v-3h3v3c0 2.206 1.794 4 4 4s4-1.794 4-4v-3h3v3c0 3.859-3.141 7-7 7z">
            </path>
          </svg>
          AUTOMÁTICO
        </div>
        <div v-else class="border border-danger rounded px-5 py-2 text-danger">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
            <circle cx="8.5" cy="10.5" r="1.5"></circle>
            <circle cx="15.493" cy="10.493" r="1.493"></circle>
            <path d="M12 14c-3 0-4 3-4 3h8s-1-3-4-3z"></path>
          </svg>
          PENDENTE
        </div>
      </div>
      <div class="leads-buttons d-flex flex-gap-5">
        <div>
          <template v-if="leads.required == 'yes'">
            <button class="btn btn-outline-dark p-2 rounded d-flex align-items-center justify-content-center" v-on:click="showLeadsConfig()">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor">
                <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"></path>
                <path
                  d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z">
                </path>
              </svg>
            </button>
          </template>
          <template v-else>
            <button class="btn btn-outline-dark p-2 rounded d-flex align-items-center justify-content-center disabled">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor">
                <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"></path>
                <path
                  d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z">
                </path>
              </svg>
            </button>
          </template>
        </div>
        <div>
          <ShareNetwork network="whatsapp" url="" :title="referral.go" class="btn btn-outline-success p-2 rounded d-flex align-items-center justify-content-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M18.403 5.633A8.919 8.919 0 0 0 12.053 3c-4.948 0-8.976 4.027-8.978 8.977 0 1.582.413 3.126 1.198 4.488L3 21.116l4.759-1.249a8.981 8.981 0 0 0 4.29 1.093h.004c4.947 0 8.975-4.027 8.977-8.977a8.926 8.926 0 0 0-2.627-6.35m-6.35 13.812h-.003a7.446 7.446 0 0 1-3.798-1.041l-.272-.162-2.824.741.753-2.753-.177-.282a7.448 7.448 0 0 1-1.141-3.971c.002-4.114 3.349-7.461 7.465-7.461a7.413 7.413 0 0 1 5.275 2.188 7.42 7.42 0 0 1 2.183 5.279c-.002 4.114-3.349 7.462-7.461 7.462m4.093-5.589c-.225-.113-1.327-.655-1.533-.73-.205-.075-.354-.112-.504.112s-.58.729-.711.879-.262.168-.486.056-.947-.349-1.804-1.113c-.667-.595-1.117-1.329-1.248-1.554s-.014-.346.099-.458c.101-.1.224-.262.336-.393.112-.131.149-.224.224-.374s.038-.281-.019-.393c-.056-.113-.505-1.217-.692-1.666-.181-.435-.366-.377-.504-.383a9.65 9.65 0 0 0-.429-.008.826.826 0 0 0-.599.28c-.206.225-.785.767-.785 1.871s.804 2.171.916 2.321c.112.15 1.582 2.415 3.832 3.387.536.231.954.369 1.279.473.537.171 1.026.146 1.413.089.431-.064 1.327-.542 1.514-1.066.187-.524.187-.973.131-1.067-.056-.094-.207-.151-.43-.263">
              </path>
            </svg>
          </ShareNetwork>
        </div>
        <div>
          <button class="btn btn-outline-default p-2 rounded d-flex align-items-center justify-content-center" v-clipboard:copy="referral.go" v-on:click="$toast.success('O link foi copiado!')">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
              <path
                d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z">
              </path>
            </svg>
          </button>
        </div>
      </div>
    </fieldset>

    <div class="row">
      <div v-for="(stat, index) of statData" :key="index" class="col-md-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <fieldset class="binary pb-0">
      <legend>
        Binário
      </legend>
      <div class="binary-config">
        <button v-on:click="changeBinarySide()">
          <div class=" d-flex align-items-center">
            <div class="side">
              <template v-if="binary.side == 'l'">Esquerdo</template>
              <template v-if="binary.side == 'r'">Direito</template>
              <template v-else>...</template>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path d="M12 16c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.084 0 2 .916 2 2s-.916 2-2 2-2-.916-2-2 .916-2 2-2z"></path>
                <path
                  d="m2.845 16.136 1 1.73c.531.917 1.809 1.261 2.73.73l.529-.306A8.1 8.1 0 0 0 9 19.402V20c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-.598a8.132 8.132 0 0 0 1.896-1.111l.529.306c.923.53 2.198.188 2.731-.731l.999-1.729a2.001 2.001 0 0 0-.731-2.732l-.505-.292a7.718 7.718 0 0 0 0-2.224l.505-.292a2.002 2.002 0 0 0 .731-2.732l-.999-1.729c-.531-.92-1.808-1.265-2.731-.732l-.529.306A8.1 8.1 0 0 0 15 4.598V4c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.598a8.132 8.132 0 0 0-1.896 1.111l-.529-.306c-.924-.531-2.2-.187-2.731.732l-.999 1.729a2.001 2.001 0 0 0 .731 2.732l.505.292a7.683 7.683 0 0 0 0 2.223l-.505.292a2.003 2.003 0 0 0-.731 2.733zm3.326-2.758A5.703 5.703 0 0 1 6 12c0-.462.058-.926.17-1.378a.999.999 0 0 0-.47-1.108l-1.123-.65.998-1.729 1.145.662a.997.997 0 0 0 1.188-.142 6.071 6.071 0 0 1 2.384-1.399A1 1 0 0 0 11 5.3V4h2v1.3a1 1 0 0 0 .708.956 6.083 6.083 0 0 1 2.384 1.399.999.999 0 0 0 1.188.142l1.144-.661 1 1.729-1.124.649a1 1 0 0 0-.47 1.108c.112.452.17.916.17 1.378 0 .461-.058.925-.171 1.378a1 1 0 0 0 .471 1.108l1.123.649-.998 1.729-1.145-.661a.996.996 0 0 0-1.188.142 6.071 6.071 0 0 1-2.384 1.399A1 1 0 0 0 13 18.7l.002 1.3H11v-1.3a1 1 0 0 0-.708-.956 6.083 6.083 0 0 1-2.384-1.399.992.992 0 0 0-1.188-.141l-1.144.662-1-1.729 1.124-.651a1 1 0 0 0 .471-1.108z">
                </path>
              </svg>
            </div>
          </div>
        </button>
      </div>
      <div class="row">
        <div class="col-6 col-md-6 col-lg-3 mb-3">
          <div class="d-flex align-items-center">
            <div>
              <div class="bg-light rounded py-1 px-2">
                <i class="bx bx-subdirectory-left font-size-24 my-1 align-middle"></i>
              </div>
            </div>
            <div class="flex-fill ml-2">
              <h5 class="mb-0 notranslate font-weight-bold">{{ binary.points.left }}</h5>
              <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">L. Esquerdo</p>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6 col-lg-3 mb-3">
          <div class="d-flex align-items-center">
            <div>
              <div class="bg-light rounded py-1 px-2">
                <i class="bx bx-subdirectory-right font-size-24 my-1 align-middle"></i>
              </div>
            </div>
            <div class="flex-fill ml-2">
              <h5 class="mb-0 notranslate font-weight-bold">{{ binary.points.right }}</h5>
              <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">L. Direito</p>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6 col-lg-3 mb-3">
          <div class="d-flex align-items-center">
            <div>
              <div class="bg-light rounded py-1 px-2">
                <i class="bx bx-check-double font-size-24 my-1 align-middle"></i>
              </div>
            </div>
            <div class="flex-fill ml-2">
              <h5 class="mb-0 notranslate font-weight-bold">{{ binary.percent }}</h5>
              <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">%</p>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-6 col-lg-3 mb-3">
          <div class="d-flex align-items-center">
            <div>
              <div class="bg-light rounded py-1 px-2">
                <i class="bx bx-dollar font-size-24 my-1 align-middle"></i>
              </div>
            </div>
            <div class="flex-fill ml-2">
              <h5 class="mb-0 notranslate font-weight-bold">{{ binary.bonus }}</h5>
              <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12">Bônus</p>
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <b-modal v-model="leads.modal" title="Atividade Diária" modal-class="modal-right" hide-footer>
      <b-form @submit.prevent="saveLeadsConfig()">
        <b-form-group label="Escolha uma opção" label-for="leads.type">
          <select v-model="leads.type" class="custom-select mb-2">
            <option value="manual">Manual</option>
            <option value="auto">Automático</option>
          </select>
        </b-form-group>
        <b-button class="mb-0" :disabled="this.leads.loading" type="submit" variant="default" size="lg">
          Salvar
          <b-spinner v-if="leads.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
        </b-button>
      </b-form>
    </b-modal>

    <b-modal v-model="binary.modal" title="Lado de Derramento" modal-class="modal-right" hide-footer>
      <b-form @submit.prevent="saveBinarySide()">
        <b-form-group label="Escolha um lado" label-for="binary.side">
          <select v-model="binary.side" class="custom-select mb-2">
            <option value="l">Lado Esquerdo</option>
            <option value="r">Lado Direto</option>
          </select>
        </b-form-group>
        <b-button class="mb-0" :disabled="this.binary.loading" type="submit" variant="default" size="lg">
          Salvar
          <b-spinner v-if="binary.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
        </b-button>
      </b-form>
    </b-modal>
  </Layout>
</template>

<style scoped>
.leads {
  position: relative;
  padding: 0.50rem 1.25rem !important;
}
.leads-config {
  position: absolute;
  top: -14px;
  right: 15px;
  border: solid 1px #D1D1E3;
  border-radius: 6px;
}

.leads-config button {
  background: #fff;
  border: 0;
  border-radius: 50%;
}

@media (min-width: 769px) {
  .leads .leads-buttons div a,
  .leads .leads-buttons div button {
    border: 0;
  }
}

@media (max-width: 768px) {
  .leads  {
    padding: 1.25rem !important;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .leads .leads-title {
    margin-bottom: 15px;
    width: 100%;
  }

  .leads .leads-status {
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }

  .leads .leads-buttons {
    display: block;
    width: 100%;
  }

  .leads .leads-buttons div {
    width: 49%;
  }

  .leads .leads-buttons div a,
  .leads .leads-buttons div button {
    width: 100%;
    text-align: center;
  }
}

.binary {
  position: relative;
}

.binary-config {
  position: absolute;
  top: -27px;
  right: 15px;
  border: solid 1px #D1D1E3;
  border-radius: 6px;
}

.binary-config button {
  background: #fff;
  border: 0;
  border-radius: 50%;
}

.binary-config button .side {
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
</style>